var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "div",
            { staticClass: "filter-bar p-2 mb-3" },
            [
              _c(
                "b-navbar",
                { attrs: { toggleable: "xl" } },
                [
                  _c("b-navbar-toggle", {
                    attrs: { target: "filter-collapse" }
                  }),
                  _c(
                    "b-collapse",
                    { attrs: { id: "filter-collapse", "is-nav": "" } },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "filter-btn reset",
                          on: {
                            click: function($event) {
                              return _vm.resetFilters()
                            }
                          }
                        },
                        [_vm._v("Reset Filters")]
                      ),
                      _c("b-form-select", {
                        staticClass: "select company",
                        attrs: {
                          options: _vm.companies,
                          "text-field": "name",
                          "value-field": "id",
                          plain: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function() {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null } },
                                  [_vm._v("Select Company")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.filters.company_id,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "company_id", $$v)
                          },
                          expression: "filters.company_id"
                        }
                      }),
                      _c("b-form-select", {
                        staticClass: "select company",
                        attrs: {
                          options: _vm.employees,
                          "text-field": "fullname",
                          "value-field": "id",
                          plain: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function() {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null } },
                                  [_vm._v("Select Assignee")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.filters.assignee_id,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "assignee_id", $$v)
                          },
                          expression: "filters.assignee_id"
                        }
                      }),
                      _c("b-form-select", {
                        staticClass: "select company",
                        attrs: {
                          options: _vm.teams,
                          "text-field": "teamname",
                          "value-field": "teamname",
                          plain: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "first",
                            fn: function() {
                              return [
                                _c(
                                  "b-form-select-option",
                                  { attrs: { value: null } },
                                  [_vm._v("Select Team")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.filters.team,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "team", $$v)
                          },
                          expression: "filters.team"
                        }
                      }),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "ml-0 ml-lg-4",
                          attrs: { value: true, "unchecked-value": false },
                          model: {
                            value: _vm.filters.completedLast30Days,
                            callback: function($$v) {
                              _vm.$set(_vm.filters, "completedLast30Days", $$v)
                            },
                            expression: "filters.completedLast30Days"
                          }
                        },
                        [_vm._v(" Completed in the last 30 days ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-2 p-2 bg-white" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-start",
                      attrs: { cols: "4" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "font-weight-bold text-uppercase bg-white border-white",
                          attrs: { variant: "light" },
                          on: {
                            click: function($event) {
                              return _vm.sort("name")
                            }
                          }
                        },
                        [
                          _vm._v(" Company "),
                          _c("b-icon", {
                            attrs: { icon: _vm.sortIcon("name") }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-center",
                      attrs: { cols: "4" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "font-weight-bold text-uppercase bg-white border-white",
                          attrs: { variant: "light" },
                          on: {
                            click: function($event) {
                              return _vm.sort("industry")
                            }
                          }
                        },
                        [
                          _vm._v(" Industry "),
                          _c("b-icon", {
                            attrs: { icon: _vm.sortIcon("industry") }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-end",
                      attrs: { cols: "4" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "font-weight-bold text-uppercase bg-white border-white",
                          attrs: { variant: "light" },
                          on: {
                            click: function($event) {
                              return _vm.sort("earliestDate")
                            }
                          }
                        },
                        [
                          _vm._v(" Due Date "),
                          _c("b-icon", {
                            attrs: { icon: _vm.sortIcon("earliestDate") }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.groupedTickets, function(group, index) {
            return _c(
              "div",
              { key: group.id, staticClass: "mb-2" },
              [
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { "no-body": "" } },
                  [
                    _c(
                      "b-card-header",
                      {
                        staticClass:
                          "p-2 d-flex align-items-center justify-content-between bg-light border-light",
                        on: {
                          click: function($event) {
                            return _vm.toggleCollapse(index)
                          }
                        }
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass:
                              "text-reset d-flex align-items-center w-100 bg-light border-light",
                            attrs: {
                              block: "",
                              variant: group._showDetails ? "dark" : "dark"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex align-items-center",
                                staticStyle: { flex: "1" }
                              },
                              [
                                _c("b-icon", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    icon: group._showDetails
                                      ? "chevron-up"
                                      : "chevron-down"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-weight-bold text-uppercase"
                                  },
                                  [_vm._v(_vm._s(group.name))]
                                ),
                                _c("span", { staticClass: "red-badge ml-2" }, [
                                  _vm._v(_vm._s(group.tickets.length))
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-muted font-weight-bold text-uppercase",
                                staticStyle: {
                                  flex: "1",
                                  "text-align": "center"
                                }
                              },
                              [_vm._v(_vm._s(group.industry))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-muted font-weight-bold",
                                staticStyle: {
                                  flex: "1",
                                  "text-align": "right"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.formatDate(group.earliestDate))
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-collapse",
                      { attrs: { visible: group._showDetails } },
                      [
                        _c(
                          "b-card-body",
                          [
                            _c("b-table", {
                              attrs: {
                                small: "",
                                items: group.tickets,
                                fields: _vm.ticketFields,
                                bordered: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "cell(duedate)",
                                    fn: function(data) {
                                      return [
                                        _c("b-form-datepicker", {
                                          staticStyle: { width: "auto" },
                                          attrs: {
                                            "date-format-options": {
                                              year: "numeric",
                                              month: "numeric",
                                              day: "numeric"
                                            }
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.addNote(
                                                data.item.id,
                                                "duedate",
                                                null,
                                                data.item.previous_duedate,
                                                null,
                                                $event,
                                                data.item,
                                                "duedate"
                                              )
                                            }
                                          },
                                          model: {
                                            value: data.item.duedate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                data.item,
                                                "duedate",
                                                $$v
                                              )
                                            },
                                            expression: "data.item.duedate"
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "cell(type)",
                                    fn: function(data) {
                                      return [
                                        _vm._v(" " + _vm._s(data.item.type)),
                                        _c("br"),
                                        data.item.onboarding === 1
                                          ? _c(
                                              "span",
                                              { staticClass: "red-badge" },
                                              [_vm._v("OB")]
                                            )
                                          : _vm._e(),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("MMM")(
                                                data.item.fordate,
                                                data.item.type
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "cell(dataentry_id)",
                                    fn: function(data) {
                                      return [
                                        _c(
                                          "div",
                                          {},
                                          [
                                            _c(
                                              "b-row",
                                              { staticClass: "text-left mb-2" },
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        staticClass: "w-100",
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.addNote(
                                                              data.item.id,
                                                              "assignee",
                                                              data.item
                                                                .dataentry_id,
                                                              data.item
                                                                .dataentry_assignee_name,
                                                              data.item
                                                                .dataentry_assignee_id,
                                                              $event,
                                                              data.item,
                                                              "dataentry_assignee_id"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.employees,
                                                        function(e) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: e.id,
                                                              domProps: {
                                                                value: e.id,
                                                                selected:
                                                                  +e.id ==
                                                                  +data.item
                                                                    .dataentry_assignee_id
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    e.firstname
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    e.lastname
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              { staticClass: "text-left" },
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        staticClass: "w-100",
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.addNote(
                                                              data.item.id,
                                                              "status",
                                                              data.item
                                                                .dataentry_id,
                                                              data.item
                                                                .dataentry_status_name,
                                                              data.item
                                                                .dataentry_status_id,
                                                              $event,
                                                              data.item,
                                                              "dataentry_status_id"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.statusOptionsDE,
                                                        function(s) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: s.id,
                                                              domProps: {
                                                                value: s.id,
                                                                selected:
                                                                  +s.id ==
                                                                  +data.item
                                                                    .dataentry_status_id
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    s.name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "cell(preliminary_id)",
                                    fn: function(data) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "w-90 mx-auto" },
                                          [
                                            _c(
                                              "b-row",
                                              { staticClass: "text-left mb-2" },
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        staticClass: "w-100",
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.addNote(
                                                              data.item.id,
                                                              "assignee",
                                                              data.item
                                                                .preliminary_id,
                                                              data.item
                                                                .preliminary_assignee_name,
                                                              data.item
                                                                .preliminary_assignee_id,
                                                              $event,
                                                              data.item,
                                                              "preliminary_assignee_id"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.employees,
                                                        function(e) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: e.id,
                                                              domProps: {
                                                                value: e.id,
                                                                selected:
                                                                  +e.id ==
                                                                  +data.item
                                                                    .preliminary_assignee_id
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    e.firstname
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    e.lastname
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              { staticClass: "text-left" },
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        staticClass: "w-100",
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.addNote(
                                                              data.item.id,
                                                              "status",
                                                              data.item
                                                                .preliminary_id,
                                                              data.item
                                                                .preliminary_status_name,
                                                              data.item
                                                                .preliminary_status_id,
                                                              $event,
                                                              data.item,
                                                              "preliminary_status_id"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.statusOptionsPR,
                                                        function(s) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: s.id,
                                                              domProps: {
                                                                value: s.id,
                                                                selected:
                                                                  +s.id ==
                                                                  +data.item
                                                                    .preliminary_status_id
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    s.name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "cell(finalization_id)",
                                    fn: function(data) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "w-90 mx-auto" },
                                          [
                                            _c(
                                              "b-row",
                                              { staticClass: "text-left mb-2" },
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        staticClass: "w-100",
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.addNote(
                                                              data.item.id,
                                                              "assignee",
                                                              data.item
                                                                .finalization_id,
                                                              data.item
                                                                .finalization_assignee_name,
                                                              data.item
                                                                .finalization_assignee_id,
                                                              $event,
                                                              data.item,
                                                              "finalization_assignee_id"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.employees,
                                                        function(e) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: e.id,
                                                              domProps: {
                                                                value: e.id,
                                                                selected:
                                                                  +e.id ==
                                                                  +data.item
                                                                    .finalization_assignee_id
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    e.firstname
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    e.lastname
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-row",
                                              { staticClass: "text-left" },
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        staticClass: "w-100",
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.addNote(
                                                              data.item.id,
                                                              "status",
                                                              data.item
                                                                .finalization_id,
                                                              data.item
                                                                .finalization_status_name,
                                                              data.item
                                                                .finalization_status_id,
                                                              $event,
                                                              data.item,
                                                              "finalization_status_id"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.statusOptionsF,
                                                        function(s) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key:
                                                                "status" + s.id,
                                                              domProps: {
                                                                value: s.id,
                                                                selected:
                                                                  +s.id ==
                                                                  +data.item
                                                                    .finalization_status_id
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    s.name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "cell(actions)",
                                    fn: function(data) {
                                      return [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.topleft",
                                                value: "Add Note",
                                                expression: "'Add Note'",
                                                modifiers: {
                                                  hover: true,
                                                  topleft: true
                                                }
                                              }
                                            ],
                                            staticClass: "mr-1",
                                            attrs: { size: "sm" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addNote(
                                                  data.item.id,
                                                  "note"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "journal-plus" }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.bottom",
                                                value: "View Notes",
                                                expression: "'View Notes'",
                                                modifiers: {
                                                  hover: true,
                                                  bottom: true
                                                }
                                              }
                                            ],
                                            staticClass: "mr-1",
                                            attrs: { size: "sm" },
                                            on: {
                                              click: function($event) {
                                                return _vm.openNotes(
                                                  data.item.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "eye" }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm.isAdmin
                                          ? _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.bottom",
                                                    value: "Delete Ticket",
                                                    expression:
                                                      "'Delete Ticket'",
                                                    modifiers: {
                                                      hover: true,
                                                      bottom: true
                                                    }
                                                  }
                                                ],
                                                staticClass: "mr-1",
                                                attrs: {
                                                  variant: "danger",
                                                  size: "sm"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteTicket(
                                                      data.item.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: { icon: "trash-fill" }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Add Notes",
            "ok-variant": "primary",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            busy: _vm.loading,
            "ok-disabled": _vm.loading
          },
          on: {
            hidden: function($event) {
              return _vm.resetNote()
            },
            ok: _vm.saveChange
          },
          scopedSlots: _vm._u([
            {
              key: "modal-ok",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "check" } }),
                  _vm._v(" Save ")
                ]
              },
              proxy: true
            },
            {
              key: "modal-cancel",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "x" } }),
                  _vm._v(" Cancel ")
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.notesmodal,
            callback: function($$v) {
              _vm.notesmodal = $$v
            },
            expression: "notesmodal"
          }
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Notes",
                "label-align": "left",
                description: (_vm.note ? _vm.note.length : 0) + "/100"
              }
            },
            [
              _c("b-textarea", {
                attrs: { maxlength: "100", required: "" },
                model: {
                  value: _vm.note,
                  callback: function($$v) {
                    _vm.note = $$v
                  },
                  expression: "note"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-footer": "",
            scrollable: "",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          },
          on: {
            hidden: function($event) {
              _vm.notelist = []
            }
          },
          model: {
            value: _vm.shownotes,
            callback: function($$v) {
              _vm.shownotes = $$v
            },
            expression: "shownotes"
          }
        },
        [
          _vm._l(_vm.notelist, function(n) {
            return _c("div", { key: n.id }, [
              _c("fieldset", { staticClass: "note" }, [
                _c("legend", [_vm._v(_vm._s(n.author))]),
                _c("small", [_vm._v(_vm._s(n.updated))]),
                _c("br"),
                _vm._v(" " + _vm._s(n.note) + " ")
              ])
            ])
          }),
          _vm.notelist.length == 0 && !_vm.loadingnotes
            ? _c("div", { staticClass: "text-center" }, [
                _vm._v(" No notes to be displayed ")
              ])
            : _vm._e()
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "delete-confirmation",
            title: "Confirm Deletion",
            "ok-title": "Delete",
            "ok-variant": "danger",
            "cancel-title": "Cancel",
            "cancel-variant": "secondary"
          },
          on: {
            ok: function($event) {
              return _vm.confirmDelete()
            },
            cancel: function($event) {
              return _vm.cancelDelete()
            }
          }
        },
        [
          _c("p", { staticClass: "mb-0" }, [
            _vm._v("Are you sure you want to delete this ticket?")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }